<template>
     <el-row v-if='searchStr.length>0 && !isNoResult'  style="width:1440px;margin:0 auto;background:#fff;">
        <el-col :span="4" >
            <div >
                <div class="search-title">
                     <span>统计时间</span>
                </div>
                <ul class="search-item-wrap">
                    <li  :class="{'active': selCycle==1}" @click="getRegionItemNumListFun(1,null,null)" >
                        <span class="search-item-name">近一月内</span>
                    </li>
                    <li :class="{'active': selCycle==2}"  @click="getRegionItemNumListFun(2,null,null)" >
                        <span class="search-item-name">近一年内</span>
                    </li>
                    <li :class="{'active': selCycle==3}" @click="getRegionItemNumListFun(3,null,null)" >
                        <span class="search-item-name">近三年内</span>
                    </li>
                </ul>
                <div class="custom_2wanX"> 
                    <span>自定义</span> 
                    <div class="time_length_2mhjy"> 
                        <div> 
                            <span class="length_text_a_gmJ">从</span> 
                            <el-date-picker v-model="c_startDate" type="date" placeholder="日期" size="small"/> 
                        </div> 
                        <div class="time_length_2mhjy"> 
                            <span class="length_text_a_gmJ">至</span>
                            <el-date-picker v-model="c_endDate" type="date" placeholder="日期" size="small"/> 
                        </div> 
                    </div>
                    <div class="btn_wrapper_3Aud7"> 
                        <el-button type="primary" size="small" @click="getRegionItemNumListFun(4,c_startDate,c_endDate)">确认</el-button>
                    </div> 
                </div>
            </div>
        </el-col>
        <el-col :span="20" style="padding:0 20px 20px 20px;border-left:1px solid #DCDFE6;">
            <div>
                <div class="search-title">
                    <span>范围选择</span>
                </div>
                <div v-loading="loading"  class="search-region-wrap clearfix">
                    <span class="item-title fontsize16">地区：</span>
                    <!-- 省级地区 -->
                    <ul class="clearfix">
                        <li v-for="region in regionList?.filter(t=> t.parent=='' || t.parent=='0' )" :key="region.code" :class="{'active':proviceCode==region.code,'is-disabled':region.disabled  }" @click='getNextRegionItemNumFun("provice",region.code,region.disabled)'>
                            <span class="search-item-name">{{region.name}}</span>
                            <span class="search-item-num">{{region.itemNum?'('+region.itemNum+')':''}}</span>
                        </li>
                    </ul>
                    <!-- 市级地区 -->
                        <ul class="search-region-sub clearfix">
                        <li v-for="region in regionList?.filter(t=>t.parent==proviceCode && proviceCode!='0' )" :key="region.code" :class="{'active':cityCode==region.code,'is-disabled':region.disabled}" @click='getNextRegionItemNumFun("city",region.code,region.disabled)'>
                            <span class="search-item-name">{{region.name}}</span>
                            <span class="search-item-num">{{region.itemNum?'('+region.itemNum+')':''}}</span>
                        </li>
                    </ul>
                    <!-- 区县级地区 -->
                    <ul class="search-region-sub clearfix">
                        <li v-for="region in regionList?.filter(t=>t.parent==cityCode )" :key="region.code" :class="{'active':districtCode==region.code,'is-disabled':region.disabled}" @click='getNextRegionItemNumFun("district",region.code,region.disabled)'>
                            <span class="search-item-name">{{region.name}}</span>
                            <span class="search-item-num">{{region.itemNum?'('+region.itemNum+')':''}}</span>
                        </li>
                    </ul>
                </div>
            </div>
            <div >
                <div class="search-title">
                    <span>数据列表</span>
                </div>
                <el-table v-loading="loading"  :data="dataList" stripe style="width: 100%" size='small' table-layout='fixed' height="440">
                    <el-table-column prop="date" label="日期" width="180" />
                    <el-table-column prop="regionName" label="地区" width="180" />
                    <el-table-column prop="title" label="标题"  >
                        <template #default="scope">
                            <el-link  :href="'/#/Link?target='+ scope.row.url" target='_blank'>{{ scope.row.title?scope.row.title:'-'}}</el-link>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </el-col>
    </el-row>
    <search-no-result v-if='searchStr.length<=0 || isNoResult' :search="searchStr" type="ecoArticle"></search-no-result>
</template>
<script>
import SearchNoResult from '@/components/SearchNoResult'
import { ref } from '@vue/reactivity'
import {getEcoArticleNum,getRegionEcoArticleNum} from '@/http/ecoApi.js'
import {getEcoArticleList} from '@/http/policyApi.js'
import {addUserLog} from '@/http/basicsApi.js'
import { useRoute } from 'vue-router'
import {parseTime} from '@/utils'
export default {
    components:{
        SearchNoResult
    },
    setup() {
        const route =useRoute(); //useRoute();//当前路由
        //const router = useRouter()
        let searchStr=ref(route.query['key']?route.query['key']:'')//搜索内容
        let isNoResult=ref(false);//是否返回结果
        let loading=ref(false);//正在加载
        let selCycle=ref(1);//默认选择的时间段
        //获取下级地区指标数据量列表
        let startDate=ref(null);//开始时间
        let endDate=ref(null);//自定义结束时间 
        let c_startDate=ref(new Date());//自定义开始时间
        let c_endDate=ref(new Date());//自定义结束时间
        let selRegionCode=ref('-1');//选中的地区编号
        let regionList=ref(null);//地区列表
        let proviceCode=ref('-1');//xuan中省份
        let cityCode=ref('-1');//选中市级
        let districtCode=ref('-1');//选中区县
        let dataList=ref(null);//经济舆情列表
        //根据标题查询经济舆情的数据量
        const getEcoArticleNumFun=async()=>{
             let params={
                    search:searchStr.value,
                }
                console.log(params);
                let result= await getEcoArticleNum(params);
                console.log(result);
                if(result.code==20000){
                    if(result.data.count<=0){
                        isNoResult.value=true;
                    }
                    else{
                        //加载地区数据量
                        //默认时间：近一个月内
                        getRegionItemNumListFun(selCycle.value,null,null);
                    }
                }
        }
        const getRegionItemNumListFun= async(dateType, start,end)=>{//type,1:重新加载指标项列表，2：加载更多指标项列表

                loading.value=true;
                selCycle.value=dateType;
                if(dateType==1){//近一月日期
                    start=parseTime(new Date().setMonth(new Date().getMonth()-1),'{y}-{m}-{d}');
                    end=null;
                }
                else if(dateType==2){//近一年
                    start=parseTime(new Date().setFullYear(new Date().getFullYear()-1),'{y}-{m}-{d}');
                    end=null;
                }
                else if(dateType==3){//近三年
                    start=parseTime(new Date().setFullYear(new Date().getFullYear()-3),'{y}-{m}-{d}');
                    end=null;
                }
                else{//自定义
                    start=start?parseTime(start,'{y}-{m}-{d}'):null;
                    end=end?parseTime(end,'{y}-{m}-{d}'):null;
                }
                startDate.value=start,
                endDate.value=end;
                selRegionCode.value='-1';//选中的地区编号
                proviceCode.value='-1';//xuan中省份
                cityCode.value='-1';//选中市级
                districtCode.value='-1';//选中区县
                dataList.value=null;
                let params={
                    title:searchStr.value,
                    startDate:start,
                    endDate:end
                }
                console.log(params);
                let result= await getRegionEcoArticleNum(params);
                console.log(result);
                if(result.code==20000){
                    
                    //循环地区列表，对本地区没有数量和下级没有数量的地区禁用（不能点击）
                    result.data.filter(t=>t.parent=='' || t.parent=='0').forEach(element => {
                        let childAry=result.data.filter(t=>t.parent==element.code && t.parent!='0');
                        //本地区是否有数据量
                        if(element.itemNum>0){
                            if(selRegionCode.value=='-1'){
                                selRegionCode.value=element.code;
                                proviceCode.value=element.code;
                            }
                            element.disabled=false;
                        }
                        else if(childAry.length>0 && childAry.filter(t=>t.itemNum>0).length>0){
                            element.disabled=false;
                        }
                        else{
                            element.disabled=true;
                        }
                        //下级地区是否有数据量
                        childAry.forEach(t=>{
                            let subChildAry=result.data.filter(tm=>tm.parent==t.code);
                            if(t.itemNum>0){
                                if(selRegionCode.value=='-1'){
                                    selRegionCode.value=t.code;
                                    cityCode.value=t.code;
                                    proviceCode.value=element.code;
                                }
                                t.disabled=false;
                            }
                            else if(subChildAry.length>0 && subChildAry.filter(t=>t.itemNum>0).length>0){
                                t.disabled=false;
                            }
                            else{
                                t.disabled=true;
                            }
                            ///下下级地区是否有数据量
                            subChildAry.forEach(tm=>{
                                if(tm.itemNum>0){
                                    if(selRegionCode.value=='-1'){
                                        selRegionCode.value=tm.code;
                                        districtCode.value=tm.code
                                        cityCode.value=t.code;
                                        proviceCode.value=element.code;
                                    }
                                    tm.disabled=false;
                                }
                                else{
                                    tm.disabled=true;
                                }
                            })
                        })
                    });
                    regionList.value=result.data;
                    //获取默认地区的经济舆情列表
                    //默认第一地区，获取指标项历史数据列表
                    //proviceCode.value=regionList.value.filter(t=> t.parent=='' || t.parent=='0' )[0].code;
                    //selRegionCode.value=proviceCode.value
                    if(selRegionCode.value!='-1'){
                        getItemDataListFun();
                    }
                    else{
                        loading.value=false;
                    }
                }
                else{
                    regionList.value=null;
                }
               
        }
        const getNextRegionItemNumFun=(type, regionCode,isdisabled)=>{
            if(isdisabled){
                return false;
            }
            if(type=="provice"){
                proviceCode.value=regionCode;
                cityCode.value='-1';
                districtCode.value='-1';
            }
            else if(type=="city"){
                cityCode.value=regionCode;
                districtCode.value='-1';
            }
            else{
                districtCode.value=regionCode;
            }
            selRegionCode.value=regionCode;
            getItemDataListFun();
        }
       
        const getItemDataListFun=async()=>{
            let params={
                pageIndex: 1,
                pageSize: 1,
                title:searchStr.value,
                regionCode:selRegionCode.value,
                startDate:startDate.value,
                endDate:endDate.value
            }
            //console.log(params);
            let result= await getEcoArticleList(params);
            //console.log(result);
            if(result.code==20000){
                dataList.value=result.data.dataList;
            }
            else{
                dataList.value=null;
            }
            loading.value=false;
            //记录用户操作日志
            addUserLog(route.name,route.fullPath,'舆情查询',JSON.stringify(params));
        }
        ///加载数据
        getEcoArticleNumFun();
        //记录用户操作日志
        addUserLog(route.name,route.fullPath,'加载',JSON.stringify({search:searchStr.value}));
        return{
            searchStr,
            loading,
            selCycle,
            c_startDate,
            c_endDate,
            parseTime,
            getRegionItemNumListFun,
            regionList,
            proviceCode,
            cityCode,
            districtCode,
            getNextRegionItemNumFun,
            dataList,
            isNoResult,
        }
    },
}
</script>
<style lang="scss" scoped>
@import '@/styles/variables.scss';
.is-disabled{
    color:#aaa;
    cursor:inherit !important;
}
.search-item-wrap li{
    line-height: 20px;
    padding: 5px 20px;
    cursor: pointer;
    span{
        display: inline-block;
        vertical-align: middle;
    }
    &:not(.is-disabled):hover{
        background-color: rgba($themeColor, 0.05);
    }
}
.search-item-wrap li.active{
    background: rgba($themeColor, 0.1);
}
// .search-item-num{
//     color:rgb(206,145,120);
// }
.search-item-wrap .search-item-name{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 75%;
    display: inline-block;
}
.search-region-wrap{
    position: relative;
    padding-left: 60px;
    font-size: 13px;
    min-height: 120px;
}
.search-region-wrap li{
    float: left;
    margin:0 10px;
    line-height: 30px;
    padding: 0 5px;
    cursor: pointer;
    &.active .search-item-name{
        color:$specialtext-color;
        font-weight: bold;
    }
    &:not(.is-disabled):hover .search-item-name {
        color:$specialtext-color;
    }
}
.search-region-wrap .search-region-sub{
    margin-top: 20px;
    background-color: rgba($themeColor, 0.1);
    border-radius: 2px;
}
.search-item-num {
    color: #fc5531;
}
.search-title{
    font-weight: 600;
    font-size: 14px;
    border-bottom:2px solid $themeColor;
    margin-bottom: 10px;
    margin-top: 20px;
    span{
        background-color: $themeColor;
        line-height: 30px;
        display: inline-block;
        padding: 0 10px;
        border-top-right-radius: 6px;
        border-top-left-radius: 6px;
        letter-spacing:1px;
        color: #fff;
    }
    i{
        float: right;
        margin-right:5px;
        line-height: 30px;
    }
    i.active{
        color: $specialtext-color;
    }
}
.item-title{
    position:absolute;
    left: 0;
    top: 3px;
}
.custom_2wanX{
    border-top: 1px solid rgba($themeColor, 0.1);
    margin: 20px 10px;
    padding-top: 10px;
}
.length_text_a_gmJ{
    color: $specialtext-color;
}
.btn_wrapper_3Aud7{
    margin-top: 10px;
    text-align: center;
}
::v-deep .el-link.el-link--default {
    color: $specialtext-color;
}
::v-deep .el-link.el-link--default:hover {
    color:darken($specialtext-color,10%);
}
</style>